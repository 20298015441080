<template>
	<div class="job-market-container">
		<SearchHeader />
		<div class="container-fluid p-0">
			<div class="row gy-6 gx-6">
				<div class="col">
					<SearchResults />
				</div>
			</div>
		</div>
		<SearchFooter />
	</div>
</template>

<script>
import SearchHeader from './SearchHeader';
import SearchResults from './results/SearchResults';
import SearchFooter from './footer/SearchFooter';

export default {
	name: 'jobmarket',
	components: {
		SearchHeader,
		SearchResults,
		SearchFooter
	},
	mounted() {
		this.$store.dispatch('executeSearch');
	}
}
</script>
